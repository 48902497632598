<template>
  <div class="error-404">
    <h1>Sorry we can't seem to find the page you're looking for</h1>
  </div>
</template>

<script>
export default {
  name: 'ErrorNotFound',
};
</script>

<template>
  <div class="error-500">
    <h1>OOPS something went wrong</h1>
  </div>
</template>

<script>
export default {
  name: 'ErrorNotFound',
};
</script>
